/* Hide toggle button by default */
.navbar-toggle-custom {
  z-index: 1029;
  display: none;
}

/* Show toggle button on small screens (less than 576px) */
@media (max-width: 575.98px) {
  .navbar-toggle-custom {
    display: block;
  }
  .navbar-direction {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.navbar-header-custom {
  display: flex;
  align-items: justify-content;
  width: 100%; /* Ensure the container takes full width */
}

/* Medium screens (576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar-toggle-custom {
    display: none;
  }
}

/* Large screens (768px and up) */
@media (min-width: 768px) {
  .navbar-toggle-custom {
    display: none;
  }
}
