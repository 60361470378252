#photo {
  width: 100%;
  height: auto;
  border-radius: 5%;
}

.photo-container {
  flex: 0 0 37%; /* Set the width of the photo container */
  margin-right: 20px; /* Add some space between the photo and text */
}

.home-container {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  margin-left: 5%;
  margin-right: 5%;
}

.home-text {
  flex: 1; /* Take remaining space */
}

/* Media query for phones */
@media (max-width: 767px) {
  .home-container {
    flex-direction: column; /* Stack the content vertically on smaller screens */
  }

  .photo-container {
    margin-bottom: 20px; /* Add some space between the photo and text on smaller screens */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Adjust alignment as needed */
  padding: 20px;
}

.list {
  width: 50%;
  margin-right: 20px;
  position: relative;
}
.list h3 {
  color: black;
  font-weight: 700;
  align-content: start;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.list ul {
  position: relative;
}
.list ul li {
  position: relative;
  left: 0;
  color: black;
  list-style: none;
  margin: 4px 0;
  border-left: 2px solid #00f1f5;
  transition: 0.5s;
  cursor: pointer;
}
.list ul li:hover {
  left: 10px;
}
.list ul li span {
  position: relative;
  padding: 8px;
  padding-left: 12px;
  display: inline-block;
  z-index: 1;
  transition: 0.5s;
}
.list ul li:hover span {
  color: #111;
}
.list ul li:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00f1f5;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}
.list ul li:hover:before {
  transform: scaleX(1);
}

.experience {
  width: 50%;
  margin-left: 20%;
  position: relative;
}

.featured-circle {
  border-radius: 100%;
  width: 250px;
  height: 250px;
  margin-top: -10px;
}

.featured-text {
  font-size: var(--h6-font-size);
  line-height: 2rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  margin-bottom: 0;
}

.featured-number {
  color: var(--primary-color);
  font-size: 8rem;
  margin: 0 10px;
}

/* Your existing CSS */

/* Media query for phones */
@media (max-width: 767px) {
  .home-container {
    flex-direction: column; /* Stack the content vertically on smaller screens */
  }

  .photo-container {
    margin-bottom: 20px; /* Add some space between the photo and text on smaller screens */
  }



  .list,
  .experience {
    width: 100%; /* Make both sections full width on smaller screens */
    margin: 0; /* Remove any margin on smaller screens */
  }
  .featured-circle {
    margin-left: 50px;
  }

  .list {
    margin-bottom: 20px; /* Add some space between the sections on smaller screens */
  }
}

.treatments-container {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
}

.treatment-column {
  list-style: none;
  padding: 0;
}

.treatment-column li {
  /* width: 400px; */
  margin: 10px;
}
